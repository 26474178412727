import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    // redirect: { name: "dashboard" },
    name: "home",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "home",
    },
    component: () => import("../views/home.vue"),
    beforeEach: (to, from, next) => {
      next({ name: "home" });
    },
  },
  {
    path: "/score/:reference",
    name: "score",
    meta: {
      is_auth: false,
      title: "Score",
    },
    component: () => import("../views/score/index.vue"),
  },
  {
    path: "/terrains",
    name: "terrains",
    meta: {
      is_auth: false,
      title: "Terrains",
    },
    component: () => import("../views/terrains/index.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
