import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import matche from './modules/matche'
import partner from './modules/partner'
import reservation from './modules/reservation'
import terrain from './modules/terrain'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    matche,
    partner,
    reservation,
    terrain
  }
})
