const constant = {
  
  //  api: "http://192.168.1.148:8888/api/",
  //  url: "http://192.168.1.148:8888/",

  url: "https://api.orient5.com/",
  api: "https://api.orient5.com/api/",

};
export default constant;
 