import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "terrains";
// initial state
const state = () => ({
  all: [],
  terrains: [],
  terrain: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getTerrain: (state) => {
    return state.terrain;
  },

  getTerrains: (state) => {
    return state.terrains;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let terrains = response.data;
      commit("SET_TERRAINS", terrains);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let terrain = response.data;
      commit("SET_TERRAIN", terrain);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "terrains-index" });
    });
  },

  async validate({ commit, dispatch }, data) {
    await axios.post(api + "_validate", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "terrains-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "terrains-index",
        });
      }
    }); 
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let terrains = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_TERRAINS", terrains);
    });
  },
};

// mutations
const mutations = {
  SET_TERRAINS(state, terrains) {
    state.all = terrains;
    state.terrains = terrains;
  },
  SET_TERRAIN(state, terrain) {
    state.terrain = terrain;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
