import constant from "../../constant";
import axios from "axios";
const api = constant.api + "matches";
// initial state
const state = () => ({
  matche: {},
});

// getters
const getters = {
  
  getMatche: (state) => {
    return state.matche;
  },

};

// actions
const actions = {
  
  async show({ commit }, reference) {
    await axios.get(api + "_tv/" + reference).then((response) => {
      let matche = response.data;
      commit("SET_MATCHE", matche);
    });
  },

};

// mutations
const mutations = {
  
  SET_MATCHE(state, matche) {
    state.matche = matche;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
