import constant from "../../constant";
import axios from "axios";
const api = constant.api + "bookings";
// initial state
const state = () => ({
  reservation: {},
});

// getters
const getters = {
  
  getReservation: (state) => {
    return state.reservation;
  },

};

// actions
const actions = {
  
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let reservation = response.data;
      commit("SET_RESERVATION", reservation);
    });
  },

};

// mutations
const mutations = {
  
  SET_RESERVATION(state, reservation) {
    state.reservation = reservation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
