<template>
  <div class="position-fixed fixed-bottom mt-5 px-5">
    <div class="scrolling-partners w-100">
      <div class="row align-items-stretch justify-content-end flex-nowrap ">
        <div
          v-for="(partner, index) in partners"
          :key="index"
          class="col-2 align-items-stretch p-2"
        >
          
        </div>
        <div
          v-for="(partner, index) in partners"
          :key="index"
          class="col-2 align-items-stretch p-2"
        >
          <div class="card shadow h-100 border-0">
            <img :src="partner.logo" class="img-card-top w-75 mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("partner/fetch");
  },
  computed: {
    ...mapGetters("partner", { partners: "getPartners" }),
  },
};
</script>

<style scoped>
.scrolling-partners {
  animation: scroll-partners 20s linear infinite;
}

@keyframes scroll-partners {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>