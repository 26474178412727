// store/modules/partner.js
import constant from "../../constant";
import axios from "axios";
const api = constant.api + "partners";
const state = {
  partners: [],
};

const getters = {
  getPartners: (state) => state.partners,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch partners from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_PARTNERS", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching partners:", error);
    }
  },

 
  
};

const mutations = {
  SET_PARTNERS: (state, partners) => (state.partners = partners),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
